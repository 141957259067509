<template>
    <v-row class="d-flex position-relative" @click="toggleMenu($event)" :ref="pinnedMessage && message.id == pinnedMessage.id ? 'refPinnedMessage' : ''">
        <!-- Pinned message design -->
        <div class="pinned-message" v-if="pinnedMessage && message.id == pinnedMessage.id">
            <v-icon>mdi-pin-outline</v-icon>
        </div>
        <!-- End Pinned message design -->

        <div v-if="isTypeStory"
             :class="['MessageItem-wrap', {right: own}, 'up']"
        >
            <div class="profile-user-icon">
                <template v-if="!own">
                    <router-link
                        :to="{
                            name: 'otherUser.profile.viewSlug',
                            params: {
                                slug: message.sender_slug,
                                from: $route.name,
                                ...( $route.name === 'user.own.channel' 
                                    ? { chat_id: $route.params.chat_id } 
                                    : { id: $route.params.id }
                                )
                            },
                        }"
                    >
                        <profile-image
                            :src="message.profile_image"
                            :username="message.sender_name"
                        />
                    </router-link>
                </template>
                <template v-else>
                    <profile-image
                        :src="message.profile_image"
                        :username="message.sender_name"
                    />
                </template>
            </div>
            <div class="pa-0 ma-0 mb-4">
                
                <div :class="[
                    'MessageItem-cloud message mb-1', own ? 'float-right' : 'float-left',
                    {'my-message mr-2': own},
                ]">
                    <div v-if="isBroadcastChat" class="font-weight-bold">{{ message.sender_name }}</div>
                </div>
                <story-view-message
                    :story="message.story"
                    :is-own="own"
                    @reload-chat="handleReloadChat"
                />
            </div>
        </div>
        <div v-else-if="isTypeAudio"
             :class="['MessageItem-wrap', {right: own}]"
        >
            <div class="profile-user-icon">
                <template v-if="!own">
                    <router-link
                        :to="{
                            name: 'otherUser.profile.viewSlug',
                            params: {
                                slug: message.sender_slug,
                                from: $route.name,
                                ...( $route.name === 'user.own.channel' 
                                    ? { chat_id: $route.params.chat_id } 
                                    : { id: $route.params.id }
                                )
                            },
                        }"
                    >
                        <profile-image
                            :src="message.profile_image"
                            :username="message.sender_name"
                        />
                    </router-link>
                </template>
                <template v-else>
                    <profile-image
                        :src="message.profile_image"
                        :username="message.sender_name"
                    />
                </template>
            </div>
            <audio-player :audio-src="message.audio_file_url" :is-own="own"></audio-player>
        </div>
        <div v-else :class="['MessageItem-wrap', {'right': own}]">
            <div class="profile-user-icon">
                <template v-if="!own">
                    <router-link
                        :to="{
                            name: 'otherUser.profile.viewSlug',
                            params: {
                                slug: message.sender_slug,
                                from: $route.name,
                                ...( $route.name === 'user.own.channel' 
                                    ? { chat_id: $route.params.chat_id } 
                                    : { id: $route.params.id }
                                )
                            },
                        }"
                    >
                        <profile-image
                            :src="message.profile_image"
                            :username="message.sender_name"
                        />
                    </router-link>
                </template>
                <template v-else>
                    <profile-image
                        :src="message.profile_image"
                        :username="message.sender_name"
                    />
                </template>
            </div>
            <div :class="[
                'MessageItem-cloud message',
                {'my-message mr-2': own, 'money': isTypeUserTip || isTypeCreatorTip},
            ]">
                <div v-if="isBroadcastChat" class="font-weight-bold">{{ message.sender_name }}</div>
                <div v-if="title" class="mb-1 MessageItem-request">{{ title }}</div>
                <div v-if="amount" class="MessageItem-amount">{{ amount }}</div>
                <div v-if="formatText"
                     class="MessageItem-text text"
                     :class="{'text-center': isTypeUserTip}"
                     v-html="formatText"
                ></div>
            </div>
        </div>
        <div class="MessageItem-date date pl-2 pr-2" :class="['MessageItem-wrap', {right: own}]">
            {{ date }}

            <span v-if="message.is_read && own" class="ml-3">
                {{ this.$t("messages.message_seen") }}
            </span>
        </div>
        <div v-if="lastMessage" ref="lastMessage"></div>
        <v-spacer :order="own ? 1 : 2"/>

        <!-- Menu Popup -->
        <v-menu
            v-model="showMenu"
            :position-x="menuPosition.x"
            :position-y="menuPosition.y"
            offset-y
            min-width="100px"
            content-class="message-pin"
            @click:outside="closeMenu"
        >
            <v-list>
                <v-list-item @click="pinMessage" class="message-pin-list">
                    <v-list-item-title class="message-title">
                        {{ $t("messages.pin") }}
                        <v-icon>mdi-pin-outline</v-icon>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-row>
</template>

<script>

const TYPE_MESSAGE = 'message';
const TYPE_STORY = 'story';
const TYPE_USER_TIP = 'user_tip';
const TYPE_CREATOR_TIP = 'creator_tip';
const TYPE_AUDIO = 'audio';


import StoryViewMessage from "../message/StoryViewMessage";
import {CURRENCY_SYMBOL} from "@/configs/constants";
import moment from "moment";
import AudioPlayer from "@/components/app/common/AudioPlayer";
import ProfileImage from "@/components/app/notifications/ProfileImage";

export default {
    name: "Message",
    data() {
    	return {
			showMenu: false,
            menuPosition: { x: 0, y: 0 },
      	};
    },
    components: {
        StoryViewMessage,
        AudioPlayer,
        ProfileImage,
    },
    props: {
        message: {
            type: Object,
            required: true
        },
        isTyping: {
            type: Boolean,
            default: false,
        },
        lastMessage: {
            type: Boolean,
            default: false,
        },
        scrollToLastMessage: {
            type: Boolean,
            default: false,
        },
        isBroadcastChat: {
            type: Boolean,
            default: false,
        },
        isBroadcastOwner: {
            type: Boolean,
            default: false,
        },
        pinnedMessage: {
            type: String | Number,
            default: null,
        },
        scrollToPinnedMessage: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        scrollToLastMessage(value) {
            if (value !== true || this.lastMessage !== true) {
                return
            }
            this.$nextTick(() => {
                this.$refs.lastMessage.scrollIntoView()
                this.$emit('scrolled')
            })
        },
        scrollToPinnedMessage(value) {
            if (value !== true) {
                return
            }
            this.$nextTick(() => {
                if (this.$refs.refPinnedMessage) {
                    this.$refs.refPinnedMessage.scrollIntoView();
                    this.$emit('scrolled-to-pin');
                }
            })
        }

    },
    computed: {
        isTypeStory() {
            return this.type === TYPE_STORY
        },
        isTypeUserTip() {
            return this.type === TYPE_USER_TIP
        },
        isTypeCreatorTip() {
            return this.type === TYPE_CREATOR_TIP
        },
        date() {
            return this.timeFormat(this.message.date)
        },
        own() {
            return this.message.own
        },
        type() {
            return this.message.type
        },
        text() {
            return this.message.message
        },
        title() {
            if (this.isTypeUserTip) {
                return this.message.story_id ? 'Tip sent from Story' : 'Tip Send'
            }
            if (this.isTypeCreatorTip) {
                const name = this.message?.meta?.creator_tip?.name;
                const price = this.message?.meta?.creator_tip?.price;
                const buyerName = this.message?.meta?.buyer.name;
                return price == 0 ? `${buyerName} requested ${name}` : `${buyerName} bought ${name} for ${price}${CURRENCY_SYMBOL}`
            }
            return null
        },
        amount() {
            if (this.isTypeUserTip) {
                return CURRENCY_SYMBOL + this.message?.meta?.tip?.amount
            }
            if (this.isTypeCreatorTip) {
                return this.message?.meta?.creator_tip?.price ? CURRENCY_SYMBOL + this.message?.meta?.creator_tip?.price : ''
            }
            return null
        },
        formatText() {
            if (this.isTypeUserTip && this.text) {
                return `"${this.text}"`
            }
            if (this.type === TYPE_STORY) {
                console.log(this.message)
                return this.message?.story?.text
            }
            return this.text
        },
        isTypeAudio() {
            return this.type === TYPE_AUDIO
        },
    },
    methods: {
        timeFormat(date, full = false) {
            if (full) {
                return moment(date).toString()
            }
            return moment(date).format('h:mm a')
        },
        handleReloadChat() {
            this.$emit('reload-chat')
        },
        toggleMenu(event) {
            // Toggle the menu and get the click position for accurate popup placement
            if (this.isBroadcastChat && this.isBroadcastOwner && this.own) {
                this.menuPosition = { x: event.clientX, y: event.clientY };
                this.showMenu = !this.showMenu;
            }
        },
        closeMenu() {
            this.showMenu = false;
        },
        pinMessage() {
            this.$emit('message-pinned', this.message.id);
        }
    },
    mounted() {
        if (this.scrollToLastMessage && this.lastMessage) {
            this.$nextTick(() => {
                this.$refs.lastMessage.scrollIntoView()
                this.$emit('scrolled')
            })
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';
/* Custom styling for the menu content */
.message-pin { display: flex; align-items: center; justify-content: center; height: 30px; width: 70px; right: 20px; left: initial !important; border-radius: 6px !important; min-width: initial !important; box-shadow: none !important; padding: 0 10px; transform: translateY(15px); }
.message-pin .v-list { width: 100%; padding: 0; background-color: white; box-shadow: none !important; } 
.message-pin .v-list .message-pin-list { min-height: initial !important; padding: 0px; }
.message-pin .v-list .message-pin-list .message-title { display: flex; justify-content: center; align-items: center; gap: 3px; line-height: 1; color: #000000; }
.message-pin .v-list .message-pin-list i { font-size: 18px; color: #000000; margin-bottom: 2px; }

.MessageItem-wrap .MessageItem-cloud.message { border-radius: 10px; padding: 6px 12px; }
.MessageItem-wrap .MessageItem-cloud.message .MessageItem-text.text { margin: 0; }

.MessageItem-date.MessageItem-wrap { padding-left: 48px !important; }
.MessageItem-date.MessageItem-wrap.right { padding-right: 48px !important; padding-left: 0px; }

.MessageItem-wrap { padding-left: 48px; position: relative; }
.MessageItem-wrap.right { padding-right: 40px; padding-left: 0px; margin-left: 0px; }
.MessageItem-wrap .profile-user-icon, .MessageItem-wrap.right .profile-user-icon { position: absolute; bottom: 4px; }
.MessageItem-wrap .profile-user-icon { left: 10px; }
.MessageItem-wrap.right .profile-user-icon { right: 10px; left: initial; }
.MessageItem-wrap.right.up .profile-user-icon { top: 0px; bottom: initial; }
.MessageItem-wrap.up .profile-user-icon { top: 0px; bottom: initial; }
.MessageItem-wrap .profile-user-icon .profile-image { height: 30px !important; width: 30px !important; }

.pinned-message { height: 25px; width: 25px; position: absolute; right: 40px; top: -10px; background-color: #f5f4e5; border-radius: 100%; z-index: 1; }  
.pinned-message i { display: flex; align-items: center; justify-content: center; font-size: 16px; line-height: 25px; color: #000000; }

.message-pin-item {
    background: #f5f4e5;
    color: #000000; 
    min-height: 40px; 
    padding: 0px;
}

/* Custom v-menu itself (e.g., border, box shadow) */
.message-pin {
    background-color: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
}


.message {
    background-color: rgb(49, 49, 49);
    border-radius: 24px;
    word-break: break-word;

    & ::v-deep a {
        display: inline-flex;
        font-weight: bold;
        margin: 0 4px;
    }
}

.message.my-message {
    background-image: $accent-background;
}

.request {
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.4)
}

.amount {
    font-size: 1.6em;
    line-height: 1em;
}

.text {
    font-size: 0.8em;
    margin: 0.5em 0;
}

.date {
    font-size: 0.8em;
    opacity: 0.5;
}

.verified-background {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: $accent-background;
}

.chat-image-user {
    &.not-me {
        width: 29px;
        height: 29px;
        background-size: cover;
        border-radius: 50%;
    }
}

.MessageItem {
    &-wrap {
        display: flex;
        width: 100%;
        flex-direction: row;

        &.right {
            justify-content: flex-end;
        }
    }

    &-avatar {
        display: flex;
        align-items: flex-end;
    }

    &-cloud {
        display: flex;
        padding: 12px;
        flex-direction: column;

        &.money {
            min-width: $message-static-width;
        }
    }

    &-request {
        font-size: 0.8em;
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        justify-content: center;
    }

    &-amount {
        font-size: 1.6em;
        line-height: 1em;
        display: flex;
        justify-content: center;
    }
}

</style>
