<template>
    <div class="SendMessage-wrapper">
        <div class="SendMessage-icon" @click="handleClickSend">
            <icon-send
                :width="getWidth"
                :height="getHeight"
            />
        </div>
        <insufficient-funds-dialog
            v-if="insufficientFundsError"
            @close="handleCloseInsufficientFundsError"
        />
        <inform-dialog
            :text="$t('dialogs.messages_disabled')"
            v-if="showTurnOffMessageDialog"
            @close="handleCloseTurnOffMessageDialog"
        />
        <direct-message-dialog
            v-if="showDirectMessageDialog"
            :subscribe_price="subscribePrice"
            :direct_price="directPrice"
            :paid_options="paidOptions"
            @subscribe="handleSubscribe"
            @direct-message="handleDirectMessage"
            @close="handleCloseDirectMessageDialog"
        />
        <chat
            v-if="showChat"
            :companion-id="companion.id"
            style="position:fixed; left: 0; top:0; width: 100vw; height: 100vh; z-index: 1000"
            @back="handleCloseChat()"
        />
    </div>
</template>

<script>
import InsufficientFundsDialog from "@/components/app/dialogs/InsufficientFundsDialog";
import DirectMessageDialog from "@/components/app/dialogs/DirectMessageDialog";
import InformDialog from "@/components/app/dialogs/InformDialog";
import IconSend from "@/assets/images/icons/icon-send.svg?inline";
import {INSUFFICIENT_FUNDS_ERROR} from "@/configs/constants";
import Chat from '@/views/app/user/chat/Chat';
import { requestCore } from "@/core";

import {mapActions, mapMutations} from "vuex";

export default {
    name: 'sendMessage',
    data() {
        return {
            showDirectMessageDialog: false,
            showTurnOffMessageDialog: false,
            insufficientFundsError: false,
            showChat: false,
            paidOptions: [],
        }
    },
    props: {
        companion: {
            type: Object,
        },
        width: {
            type: Number,
            default: 0,
        },
        height: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        directPrice() {
            return this.companion.directMessagePrice
        },
        subscribePrice() {
            return this.companion.subscribePrice
        },
        getWidth() {
            return this.width > 0 ? this.width : 29;
        },
        getHeight() {
            return this.height > 0 ? this.height : 26;
        },
    },
    methods: {
        ...mapMutations(["showSnackBar"]),
        ...mapActions([
            'setOtherUser',
            'buyDirectMessage',
            'subscribe',
        ]),
        handleCloseDirectMessageDialog() {
            this.showDirectMessageDialog = false
        },
        handleCloseTurnOffMessageDialog() {
            this.showTurnOffMessageDialog = false
        },
        handleCloseInsufficientFundsError() {
            this.insufficientFundsError = false
        },
        async handleSubscribe() {
            this.subscribeInfo = false
            this.showDirectMessageDialog = false
            await this.subscribe(this.companion.id).then(res => {
                if (typeof res.redirectUrl !== 'undefined') {
                    this.$window.location = res.redirectUrl
                }
                if (typeof res.success !== 'undefined') {
                    this.$store.commit('showSnackBar', res.success)
                }
            })
            await this.setOtherUser(this.companion.id)
        },
        async handleClickSend() {
            this.$emit('open-chat-box');
            await requestCore
                .POST(
                    "messages/check-permission-to-send",
                    { user_id: this.companion.id },
                    "/api/messages/check-permission-to-send"
                )
                .then(() => {
                    this.showChat = true;
                })
                .catch(({ response }) => {
                    const { data, status } = response;
                    if (status === 400) {
                        if (response.data.err_key === "should_pay_option") {
                            this.showDirectMessageDialog = true;
                            this.paidOptions = Array.isArray(
                                data.err_paid_options
                            )
                                ? data.err_paid_options
                                : [];
                        }
                        if (response.data.err_key === "cannot_send_msg") {
                            this.showSnackBar(response.data.err_msg);
                        }        
                    } else {
                        this.showSnackBar(
                            this.$t("messages.something_went_wrong")
                        );
                    }
            });
        },
        handleCloseChat() {
            this.$emit('close-chat-box');
            this.showChat = false;
        },
        async handleDirectMessage() {
            await this.buyDirectMessage(this.companion.id).then(res => {
                if (typeof res.redirectUrl !== 'undefined') {
                    this.$window.location = res.redirectUrl
                }
                if (typeof res.success !== 'undefined') {
                    this.setOtherUser(this.companion.id)
                    const chat = !this.companion.directMessageTurnOff && this.companion.canSendMessages ? {
                        name: 'user.own.chat',
                        params: {id: this.companion.id}
                    } : null
                    if (chat) {
                        this.$router.push(chat)
                    }
                }
            }).catch((e) => {
                if (e.response.data.err_key === INSUFFICIENT_FUNDS_ERROR) {
                    this.insufficientFundsError = true
                }
            })
            await this.setOtherUser(this.companion.id)
            this.handleCloseDirectMessageDialog()
        },
    },
    components: {
        InsufficientFundsDialog,
        DirectMessageDialog,
        InformDialog,
        IconSend,
        Chat
    }
}
</script>

<style lang="scss">
.SendMessage {
    &-wrapper,
    &-icon {
        justify-content: center;
        align-items: center;
        display: flex;
    }
}
</style>

